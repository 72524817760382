<template>
  <div class="smb-client-wrapper">
    <template>
      <SmbTitles
        is-show-title
        is-show-sub-title
        title="Custom content"
        :sub-title="pageTitle"
      />
      <div class="form-wrapper" v-if="!createdContentList.length">
        <SmbTextarea
          :valueProp="personalText"
          placeholder="Example: I had a dinner date with my wife to celebrate our 15th anniversary in an amazing Italian restaurant "
          @propWasUpdated="personalText = $event"
        />
        <div class="media-container">
          <div class="media-container__uploader">
            <v-checkbox
              v-model="isCheckedUploadFiles"
              hide-details
              class="shrink mr-2 mt-0"
              @click="clickedOnCheckedUploaderCheckboxHandler"
            ></v-checkbox>
            <SmbFileUploader
              cutomClass="mb-uploader"
              :filesProp="file"
              hide-details
              element-id="myCheck"
              placeholder="Select your files"
              appendIcon="mdi-upload"
              label="Upload Media"
              :acceptProp="allowedFormats"
              @filesWasUpdated="updatedFile($event)"
              @dialogCanceledWithoutSelectingFiles="
                dialogCanceledWithoutSelectingFilesHandler
              "
              @clickedOnFileInput="clickedOnFileInputHandler"
            />
          </div>
          <div class="media-container__choose-img-container">
            <v-checkbox
              v-model="isCheckedChooseImageForMe"
              hide-details
              label="Choose an image for me"
              class="shrink mr-2 mt-0"
            ></v-checkbox>
          </div>
        </div>
        <div class="choose-type-container">
          <div class="choose-type-container__title">
            <div class="high">Choose design type</div>
            <div class="small">you can always change the design later</div>
          </div>
          <div class="choose-type-container__content">
            <div
              class="content-block"
              :class="{
                post: getCurrentCreationContentType === 'post',
                story: getCurrentCreationContentType === 'story',
                active:
                  selectedContentTypeId &&
                  selectedContentTypeId === contentType.id,
                'not-active':
                  (!isCheckedChooseImageForMe && isFileTypeVideo) ||
                  (selectedContentTypeId &&
                    selectedContentTypeId !== contentType.id),
              }"
              v-for="contentType in getCurrentCreationContentType === 'post'
                ? postTypes
                : storyTypes"
              :key="contentType.id"
              @click="selectTypeHandler(contentType)"
            >
              <div class="title">{{ contentType.title }}</div>
              <div class="logo">
                <img :src="contentType.imgSrc" :alt="contentType.id" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="ready-content-container">
        <PostList
          :dividerIsShow="false"
          :posts="
            getCurrentCreationContentType === 'post' ? createdContentList : []
          "
          :stories="
            getCurrentCreationContentType === 'story' ? createdContentList : []
          "
          :isShowStoryTitle="false"
          postType="smb-dashboard"
          :isFacebookPlatform="false"
          :currentCustomer="getCurrentSmbCustomer"
          :isShowSwitch="false"
          :isShowRating="false"
          isHeaderClient
          isShowSubmitBtn
          isCanAddACoupon
          isShowEditIconForClient
          isShowIndication
          isShowSmbAction
          isSmbPendingPage
          isContentCalendarPage
          :isCanDeleteContent="false"
          isCanSaveForLater
          @editTemplateImage="editTemplateImageHandler($event)"
          @updateSchedule="updateScheduleHandler($event)"
          @publishSmbPostNow="
            actionsSmbPostHandler({ item: $event, actionType: 'now' })
          "
          @scheduleSmbPost="
            actionsSmbPostHandler({ item: $event, actionType: 'schedule' })
          "
          @saveForLater="
            actionsSmbPostHandler({ item: $event, actionType: 'saveForLater' })
          "
          @updateCaptionValues="updateCaptionValuesHandler($event)"
        />
        <template v-if="createdContentList.length < 3">
          <v-tooltip
            v-if="getCurrentCreationContentType === 'post'"
            :z-index="900"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="suggest-post-container" v-bind="attrs" v-on="on">
                <div @click="generateHandler">
                  <div class="suggest-img-container">
                    <img
                      class="suggest-post-container__img"
                      src="@/assets/img/SaasGeneratePost.png"
                      alt=""
                    />
                    <img
                      class="suggest-post-container__img-hover"
                      src="@/assets/img/SaasGeneratePostHover.png"
                      alt=""
                    />
                    <Loader
                      v-if="isFetchingSuggestedPost"
                      z-index10-custom-class
                      class="loader"
                    />
                  </div>
                  <div class="suggest-post-container__text">
                    Suggest new post
                  </div>
                </div>
              </div>
            </template>
            <span>Click here to generate a new AI suggested Post.</span>
          </v-tooltip>
          <v-tooltip v-else :z-index="900" top>
            <template v-slot:activator="{ on, attrs }">
              <div class="suggest-story-container" v-bind="attrs" v-on="on">
                <div @click="generateHandler">
                  <div class="suggest-img-container">
                    <img
                      class="suggest-story-container__img"
                      src="@/assets/img/SaasGenerateStory.png"
                      alt=""
                    />
                    <img
                      class="suggest-story-container__img-hover"
                      src="@/assets/img/SaasGenerateStoryHover.png"
                      alt=""
                    />
                    <Loader
                      v-if="isFetchingSuggestedStory"
                      z-index10-custom-class
                      class="loader"
                    />
                  </div>
                  <div class="suggest-story-container__text">
                    Suggest new story
                  </div>
                </div>
              </div>
            </template>
            <span>Click here to generate a new AI suggested Story.</span>
          </v-tooltip>
        </template>
      </div>
      <div class="footer-buttons">
        <SmbActionBtn
          class="arrow-button"
          :class="{ 'full-width': isMobile() && createdContentList.length }"
          text="Back"
          isUseIcon
          iconName="mdi-keyboard-backspace"
          :width="isMobile() && createdContentList.length ? '100%' : '20%'"
          @click="$router.push({ name: 'generate-content' })"
        />
        <SmbActionBtn
          v-if="!createdContentList.length"
          width="80%"
          customClass="back-btn"
          :isLoading="isLoadingGenerateBtn"
          :text="isCanGenerate ? 'Generate' : 'Cancel'"
          :emitName="isCanGenerate ? 'generate' : 'cancel'"
          @cancel="$router.push({ name: 'generate-content' })"
          @generate="checkIsNeedBrandingUpdatesBeforeCreateContent"
        />
      </div>
      <SmbAddBrandingDetailsPopUp
        :isShow="isShowBrandingPopUp"
        :actionType="isNeedToUpdateBranding"
        :buttonsData="{
          personalText: personalText,
          file: file,
          type: getCurrentCreationContentType,
          isCheckedUploadFiles: isCheckedUploadFiles,
          isCheckedChooseImageForMe: isCheckedChooseImageForMe,
          selectedContentTypeId: selectedContentTypeId,
        }"
        :emit-name="emitNameForActionAfterPopup"
        @close="isShowBrandingPopUp = false"
        @generateHandler="generateHandler"
      />
      <SmbUndoAction
        @closeActionNotification="closeActionNotification()"
        @undoAction="backItem()"
        :isShow="undoActionIsShow"
        :actionType="activeAction && activeAction.type ? activeAction.type : ''"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import SmbTitles from "@/components/smb-client/SmbTitles";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn";
import SmbTextarea from "@/components/smb-client/SmbTextarea";
import SmbFileUploader from "@/components/smb-client/SmbFileUploader";
import SmbAddBrandingDetailsPopUp from "@/components/smb-client/SmbAddBrandingDetailsPopUp.vue";
import smbMethods from "@/mixins/smbMethods";

import uploader from "@/mixins/uploader";
import H from "@/utils/helper";
import LogRocket from "logrocket";
import Post from "@/models/Post";
import PostList from "@/components/createPost/PostList.vue";
import PostTooltip from "@/models/Post/PostTooltip";
import Loader from "@/components/global/Loader.vue";
import SmbUndoAction from "@/components/smb-client/SmbUndoAction.vue";
import { isMobile } from "mobile-device-detect";
export default {
  name: "PersonalUpdate",
  data: () => ({
    personalText: "",
    file: null,
    isLoadingGenerateBtn: false,
    isShowBrandingPopUp: false,
    emitNameForActionAfterPopup: "",
    isCheckedUploadFiles: false,
    isCheckedChooseImageForMe: true,
    selectedContentTypeId: null,
    createdContentList: [],
    isFetchingSuggestedPost: false,
    isFetchingSuggestedStory: false,
    initialPostData: null,
    undoActionIsShow: false,
    activeAction: null,
    timer: null,
    currentPost: null,
  }),
  async created() {
    if (
      this.$route.params &&
      this.$route.params.emitName &&
      !H.isEmpty(this.$route.params.buttonsData)
    ) {
      let {
        personalText,
        file,
        type = "post",
        isCheckedUploadFiles,
        isCheckedChooseImageForMe,
        selectedContentTypeId,
      } = this.$route.params.buttonsData;
      this.personalText = personalText;
      this.file = file;
      this.setCurrentCreationContentType(type);
      this.isCheckedUploadFiles = isCheckedUploadFiles;
      this.isCheckedChooseImageForMe = isCheckedChooseImageForMe;
      this.selectedContentTypeId = selectedContentTypeId;
      await this[this.$route.params.emitName]();
    }
  },
  watch: {
    isCheckedUploadFiles(newValue, oldValue) {
      this.isCheckedChooseImageForMe = oldValue;
    },
    isCheckedChooseImageForMe(newValue, oldValue) {
      this.isCheckedUploadFiles = oldValue;
    },
    file(newValue) {
      if (newValue) {
        this.isCheckedUploadFiles = true;
        this.isCheckedChooseImageForMe = false;
      } else {
        this.isCheckedUploadFiles = false;
        this.isCheckedChooseImageForMe = true;
      }
    },
  },
  mixins: [uploader, smbMethods],
  computed: {
    ...mapGetters("creationContentType", ["getCurrentCreationContentType"]),
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    isCanGenerate() {
      const isUploadedFile = this.isCheckedChooseImageForMe
        ? true
        : !!this.file;
      return this.personalText.trim() !== "" && isUploadedFile;
    },
    popUpTextFirstPath() {
      return `The new personal content ${this.getCurrentCreationContentType}  is now being generated.`;
    },
    popUpTextSecondPath() {
      return this.getCurrentSmbCustomer.primary_phone
        ? `You will be notified via SMS to ${H.formatPhoneNumber(
            this.getCurrentSmbCustomer.primary_phone,
            false
          )} once ready (about 1 min).`
        : "You can check it out in the Pending content section in ~30 seconds.";
    },
    popUpText() {
      return `${this.popUpTextFirstPath} ${this.popUpTextSecondPath}`;
    },
    postTypes() {
      return [
        {
          id: 1,
          title: "Just an image",
          imgSrc: require("@/assets/img/saas/customContent/post/post_just_image.png"),
        },
        {
          id: 2,
          title: "Image with logo",
          imgSrc: require("@/assets/img/saas/customContent/post/post_with_logo.png"),
        },
        {
          id: 3,
          title: "Image with text",
          imgSrc: require("@/assets/img/saas/customContent/post/post_with_text.png"),
        },
      ];
    },
    storyTypes() {
      return [
        {
          id: 1,
          title: "Just an image",
          imgSrc: require("@/assets/img/saas/customContent/story/story.png"),
        },
        {
          id: 2,
          title: "Image with logo",
          imgSrc: require("@/assets/img/saas/customContent/story/story_with_logo.png"),
        },
        {
          id: 3,
          title: "Image with text",
          imgSrc: require("@/assets/img/saas/customContent/story/story_with_text.png"),
        },
      ];
    },
    pageTitle() {
      return this.createdContentList.length
        ? "Edit the outcome and post/schedule once ready"
        : "Tell us in a few words what do you want us to talk about or describe a photo you wish to share";
    },
    isFileTypeVideo() {
      return this.file ? H.isVideo(this.file.name) : false;
    },
    allowedFormats() {
      return this.getCurrentCreationContentType === "post"
        ? "image/*, video/mp4, video/mov"
        : "image/*";
    },
  },
  components: {
    SmbUndoAction,
    Loader,
    PostList,
    SmbTitles,
    SmbActionBtn,
    SmbTextarea,
    SmbFileUploader,
    SmbAddBrandingDetailsPopUp,
  },
  methods: {
    isMobile() {
      return isMobile;
    },
    ...mapActions("smbClientSelection", ["createPosts"]),
    ...mapActions("calendar", ["getPost"]),
    ...mapActions("createPost", ["deletePost"]),
    ...mapActions("smbPost", ["fetchAmountsPostsToReview", "actionSmbPosts"]),
    ...mapMutations("smbClientSelection", ["setPopupSettings"]),
    ...mapMutations("creationContentType", ["setCurrentCreationContentType"]),
    ...mapMutations("smbPost", ["setUnselectedSuggestedContent"]),
    async generateHandler() {
      try {
        this.isShowBrandingPopUp = false;
        LogRocket.track("content_generation_button");

        this.getCurrentCreationContentType === "post"
          ? (this.isFetchingSuggestedPost = true)
          : (this.isFetchingSuggestedStory = true);

        const templateType =
          this.selectedContentTypeId === 1
            ? "custom_image"
            : this.selectedContentTypeId === 3
            ? "custom_text"
            : "custom_logo";

        let params = {
          customerId: this.getCurrentSmbCustomer.id,
          type: this.getCurrentCreationContentType,
          realTime: true,
          topicSubId: ["69aab4fa-d0fd-11ec-bd7a-96719b41e4aa"],
          context: this.personalText,
          bySmb: true,
          templateType:
            this.isCheckedChooseImageForMe || !this.isFileTypeVideo
              ? templateType
              : undefined,
        };

        this.isLoadingGenerateBtn = true;

        if (this.file && this.isCheckedUploadFiles) {
          const { mediaId } = await this.uploadFileHandler(
            [...[this.file]],
            this.getCurrentSmbCustomer.id
          );

          params.mediaId = mediaId;
        }

        const response = await this.createPosts(params);

        if (response.length) {
          let defaultTooltip = {
            color: "#1D3427",
            hint: "Generating content...",
            textColor: "",
            tip: "",
            type: 3,
          };

          this.createdContentList.push(
            new Post({
              ...{ id: response[0].postId },
              ...{
                type: this.getCurrentCreationContentType,
                isSkeletonLoading: true,
                tooltip: [new PostTooltip(defaultTooltip)],
              },
            })
          );

          this.isLoadingGenerateBtn = false;
          this.getCurrentCreationContentType === "post"
            ? (this.isFetchingSuggestedPost = false)
            : (this.isFetchingSuggestedStory = false);
        }

        this.createdContentList.forEach((obj) => {
          if (obj.isSkeletonLoading) {
            obj.intervalId = setInterval(
              () => this.getContentById(obj.id),
              5000
            );
          }
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async getContentById(id) {
      let list = await this.getPost(id);

      if (list.length) {
        let content = list[0];

        this.createdContentList = this.createdContentList.map((obj) => {
          if (obj.id === content.calendarPostId || obj.id === content.id) {
            const isMediaHasTemplate =
              !!content.media.length &&
              content.media.some(
                (mediaItem) => mediaItem.template && mediaItem.template.id
              );

            const isMediaHasVideo =
              content.type === "post" &&
              !!content.media.length &&
              content.media.some(
                (mediaItem) =>
                  mediaItem.mediaUrl && H.isVideo(mediaItem.mediaUrl)
              );

            content.isSkeletonLoading = !isMediaHasTemplate || !isMediaHasVideo;
            content.intervalId = obj.intervalId;

            if (isMediaHasTemplate || isMediaHasVideo) {
              clearInterval(content.intervalId);
              content.isSkeletonLoading = false;
              delete content.intervalId;
            }
            return content;
          } else {
            return obj;
          }
        });
      }
    },
    async checkIsNeedBrandingUpdatesBeforeCreateContent() {
      if (this.isNeedToUpdateBranding) {
        this.isShowBrandingPopUp = true;
        this.emitNameForActionAfterPopup = "generateHandler";
      } else {
        await this.generateHandler();
      }
    },
    dialogCanceledWithoutSelectingFilesHandler() {
      this.isCheckedChooseImageForMe = true;
    },
    clickedOnFileInputHandler() {
      this.isCheckedUploadFiles = true;
    },
    clickedOnCheckedUploaderCheckboxHandler() {
      if (this.isCheckedUploadFiles && !this.file) {
        document.getElementById("myCheck").click();
      }
    },
    editTemplateImageHandler(postData) {
      let searchPost = this.createdContentList.find(
        (content) => content.id === postData.id
      );
      if (searchPost) {
        searchPost.media = [];
        setTimeout(() => {
          searchPost.media = postData.media;
        }, 1);
      }
    },
    updateScheduleHandler(postData) {
      let searchPost = this.createdContentList.find(
        (content) => content.id === postData.postId
      );

      searchPost
        ? (searchPost.scheduledForStr = postData.scheduledForStr)
        : null;
    },

    resetLoadingForActionButtons() {
      this.createdContentList.forEach((item) => {
        item.isLoadingSaveForLater = false;
        item.isLoadingPublishNow = false;
        item.isLoadingSchedule = false;
      });
    },

    setLoadingForActionButtons(data) {
      this.resetLoadingForActionButtons();
      let postIndex = this.createdContentList.findIndex(
        (i) => data.item.id === i.id
      );
      if (postIndex >= 0) {
        switch (data.actionType) {
          case "saveForLater":
            this.createdContentList[postIndex].isLoadingSaveForLater = true;
            break;
          case "now":
            this.createdContentList[postIndex].isLoadingPublishNow = true;
            break;
          case "schedule":
            this.createdContentList[postIndex].isLoadingSchedule = true;
            break;
          default:
            console.log("Error");
        }
      }
    },
    async startAction(data) {
      this.setLoadingForActionButtons(data);
      switch (data.actionType) {
        case "saveForLater":
          this.currentPost = data.item;
          break;
        case "now":
          if (this.currentPost) {
            this.initialPostData = {
              item: H.deepCopy(this.currentPost),
              type: data.actionType,
            };
            await this.undoPost();
          }

          this.currentPost = data.item;
          await this.publishSmbPostNowHandler(data.item);
          await this.fetchAmountsPostsToReview({
            customerId: this.getCurrentSmbCustomer.id,
          });
          break;
        case "schedule":
          if (this.currentPost) {
            this.initialPostData = {
              item: H.deepCopy(this.currentPost),
              type: data.actionType,
            };
            await this.undoPost();
          }

          this.currentPost = data.item;
          await this.scheduleSmbPostHandler(data.item);
          await this.fetchAmountsPostsToReview({
            customerId: this.getCurrentSmbCustomer.id,
          });
          break;
        default:
          console.log("Error");
      }
    },
    async scheduleSmbPostHandler(data) {
      try {
        this.currentPost = data;
        const params = {
          contentCaptionId: this.currentPost.contentCaptionId,
          customerApprove: "published",
          id: this.currentPost.id,
          text: this.currentPost.text,
          byCustomer: true,
        };
        await this.actionWithPost(params);
      } catch (e) {
        console.log(e);
      }
    },
    async publishSmbPostNowHandler(data) {
      try {
        this.currentPost = data;
        const params = {
          contentCaptionId: this.currentPost.contentCaptionId,
          customerApprove: "rush",
          id: this.currentPost.id,
          text: this.currentPost.text,
          byCustomer: true,
        };
        await this.actionWithPost(params);
      } catch (e) {
        console.log(e);
      }
    },
    actionsSmbPostHandler(data) {
      clearTimeout(this.timer);
      this.initialPostData = null;
      this.setInitialPostData(data);
      this.activeAction = {
        item: data.item,
        type: data.actionType,
      };

      this.startAction(data);
      this.undoActionIsShow = true;
      this.startActionTimout();
    },
    startActionTimout() {
      this.timer = setTimeout(() => {
        this.activeAction = null;
        this.undoActionIsShow = false;

        this.createdContentList.forEach(async (contentItem) => {
          if (contentItem.id !== this.currentPost.id) {
            await this.deletePost(contentItem.id);
          }
        });

        this.$router.push({ name: "generate-content" });
      }, 5000);
    },
    setInitialPostData(data) {
      let searchPost = this.createdContentList.find(
        (content) => content.id === data.item.id
      );
      this.initialPostData = {
        item: H.deepCopy(searchPost),
        type: data.actionType,
      };
    },
    amountPosts() {
      this.setAmountPostsToReview(this.posts.length + this.stories.length);
    },
    closeActionNotification() {
      // this.resetLoadingForActionButtons();
      // clearTimeout(this.timer);
      //   this.initialPostData = null;
      this.undoActionIsShow = false;
      // this.activeAction = null;
    },
    async backItem() {
      this.resetLoadingForActionButtons();
      clearTimeout(this.timer);
      this.undoActionIsShow = false;
      this.activeAction = null;
      await this.undoPost();
    },
    async undoPost() {
      this.createdContentList.forEach((content) => {
        content.isLoadingScheduleFor = false;
        content.isLoadingPublishNow = false;
        content.isLoadingSaveForLater = false;
      });

      try {
        const params = {
          id: this.initialPostData.item.id,
          customerApprove: "none",
          scheduledForStr: this.initialPostData.item.scheduledFor
            ? this.initialPostData.item.scheduledForStr
            : "",
          scheduledFor: this.initialPostData.item.scheduledFor,
        };
        await this.actionWithPost(params);
        this.currentPost = null;
        this.initialPostData = null;
      } catch (e) {
        console.log(e);
      }
    },
    async actionWithPost(params) {
      try {
        await this.actionSmbPosts(params);
      } catch (e) {
        console.log(e);
      }
    },
    updateCaptionValuesHandler(params) {
      const foundIndex = this.createdContentList.findIndex(
        (post) => post.id === params.postId
      );
      this.createdContentList.splice(foundIndex, 1, {
        ...this.createdContentList[foundIndex],
        ...params.newValues,
      });
    },
    selectTypeHandler(contentType) {
      if (!this.isFileTypeVideo) {
        this.selectedContentTypeId =
          this.selectedContentTypeId !== contentType.id ? contentType.id : null;
      }
    },
    updatedFile(file) {
      this.file = file;

      if (this.isFileTypeVideo) {
        this.selectedContentTypeId = null;
      }
    },
  },
  async beforeDestroy() {
    let contentToDelete = [...this.createdContentList];

    if (this.currentPost && this.currentPost.id) {
      contentToDelete = contentToDelete.filter(
        (item) => item.id !== this.currentPost.id
      );
    }

    for (const contentItem of contentToDelete) {
      await this.deletePost(contentItem.id);
    }
  },
};
</script>

<style scoped lang="scss">
.smb-client-wrapper {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f3f2f3;
  height: 100%;

  .form-wrapper {
    padding: 10px 20px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    .media-container {
      display: flex;
      flex-direction: column;
      &__uploader {
        display: flex;
        flex-direction: row;
        max-width: 475px;
        width: 100%;
        align-items: center;
      }
      &__choose-img-container {
        padding-top: 20px;
        ::v-deep {
          .v-label {
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
          }
        }
      }
    }
    .choose-type-container {
      padding-top: 50px;
      &__title {
        display: flex;
        flex-direction: column;
        .high,
        .small {
          color: #1d1d1b;
          font-style: normal;
          line-height: 150%;
        }
        .high {
          font-weight: 700;
          font-size: 25px;
          padding-right: 10px;
        }
        .small {
          font-weight: 500;
          font-size: 15px;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        .content-block {
          width: 100%;
          max-width: 220px;
          background-color: #ffffff;
          border: 1px solid black;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
          .title {
            color: #1d1d1b;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            padding: 16px 0 10px 0;
          }
          .logo {
            width: 100%;
            padding-bottom: 16px;
            img {
              width: 100%;
            }
          }
          &.active {
            background-color: #92c4ff;
          }
          &.not-active {
            opacity: 0.3;
            cursor: not-allowed;
          }
          &.post {
            .logo {
              max-width: 91px;
            }
          }
          &.story {
            .logo {
              max-width: 70px;
            }
          }
        }
      }
    }
    @media screen and (min-width: 760px) {
      padding: 60px 20px 35px 20px;
      max-width: 719px;
      ::v-deep {
        .v-textarea {
          .v-input__slot {
            height: 154px !important;
            margin-bottom: 30px;
          }
        }
      }
      .media-container {
        flex-direction: row;
        align-items: center;
        &__uploader {
          padding-right: 50px;
        }
        &__choose-img-container {
          padding-top: unset;
        }
      }
      .choose-type-container {
        &__title {
          flex-direction: row;
          align-items: end;
          padding-bottom: 16px;
          .high {
            font-size: 23px;
          }
          .small {
            font-size: 15px;
          }
        }
        &__content {
          flex-direction: row;
          justify-content: space-between;
          align-items: unset;
          padding-top: unset;
          .content-block {
            margin-bottom: unset;
          }
        }
      }
    }
  }
  .footer-buttons {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    letter-spacing: 0;
    position: relative;
    @media screen and (min-width: 760px) {
      align-items: center;
      justify-content: center;
      padding-bottom: 30px;
    }
    .button-item {
      width: 100%;
      @media screen and (min-width: 760px) {
        width: 100%;
        max-width: 315px;
      }
    }
    .arrow-button {
      width: 20%;
      position: absolute;
      left: 0;
      z-index: 10;
      &.full-width {
        width: 100%;
      }
      ::v-deep {
        .text {
          display: none;
        }
      }
      @media screen and (min-width: 760px) {
        ::v-deep {
          left: 10px;
          width: 120px !important;
          button {
            background: transparent !important;
            color: #1d1d1b !important;
            box-shadow: none;
            width: 120px !important;
            &:before {
              display: none;
            }
            .v-ripple__container {
              display: none !important;
            }
            .text {
              display: inline-flex;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
  .ready-content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 550px) {
      flex-direction: column-reverse;
    }
    ::v-deep {
      .post-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @media screen and (max-width: 500px) {
          flex-direction: column-reverse;
        }
        &__item {
          width: 271px;
          @media screen and (max-width: 500px) {
            width: 100%;
            .instagram-post,
            .instagram-story {
              height: 650px;
            }
          }
        }
      }
    }
    .suggest-img-container {
      position: relative;
      display: inline-flex;

      .loader {
        position: absolute;
      }
    }
  }
  ::v-deep {
    @media screen and (max-width: 500px) {
      .post-wrapper {
        width: 100%;
      }
      .post-list {
        width: 100%;
      }
    }
  }
}
.suggest-post-container,
.suggest-story-container {
  width: 100%;
  max-width: 100px;
  cursor: pointer;
  @media screen and (max-width: 500px) {
    padding-top: 20px;
  }
  &__img {
    width: 100%;
  }
  .suggest-story-container__img-hover,
  .suggest-post-container__img-hover {
    display: none;
  }
  &:hover {
    cursor: pointer;
    .suggest-post-container__img,
    .suggest-story-container__img {
      display: none;
    }
    .suggest-post-container__img-hover,
    .suggest-story-container__img-hover {
      display: block;
      width: 100%;
    }
  }
  &__text {
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-align: center;
  }
}
</style>
