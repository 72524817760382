<template>
  <div class="action-notification" v-if="isShow">
    <div class="action-notification__text">Item {{ actionText }}</div>
    <div class="action-notification__buttons">
      <span class="action-notification__undo" @click="$emit('undoAction')"
        >Undo</span
      >
      <span
        class="action-notification__close"
        @click="$emit('closeActionNotification')"
      >
        <i class="icon-close"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmbUndoAction",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    actionType: {
      type: String,
      default: "",
    },
  },
  computed: {
    actionText() {
      return this.actionType === "reject"
        ? "rejected"
        : this.actionType === "save"
        ? "saved for later"
        : this.actionType === "now"
        ? "published now"
        : this.actionType === "saveForLater"
        ? "saved for later"
        : this.actionType === "schedule"
        ? "scheduled"
        : "";
    },
  },
};
</script>

<style scoped lang="scss">
.action-notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1d1b;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #fff;
  z-index: 100;
  position: fixed;
  bottom: 100px;
  right: 20px;
  padding: 11px 13px;
  width: 100%;
  max-width: 351px;
  @media screen and (max-width: 500px) {
    left: 15px;
    right: 15px;
    width: auto;
    max-width: inherit;
  }
  &__text {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    margin-right: 10px;
  }
  &__buttons {
    display: inline-flex;
    align-items: center;
  }
  &__close {
    font-size: 11px;
    cursor: pointer;
    margin-left: 20px;
  }
  &__undo {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #92c4ff;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: darken(#92c4ff, 10);
    }
  }
}
</style>
